export function getProductLink(productName: string, productNumber: string) {
  return `/shop/product/${transformStringForUrl(productName)}/${encodeURI(productNumber)}`;
}

export function transformBreadcrumbsForUrl(breadcrumbs: string[]) {
  return breadcrumbs
    .map((breadcrumb) => transformStringForUrl(breadcrumb))
    .join('/');
}

export function transformStringForUrl(name: string) {
  if (!name) return '-';
  return name
    .toLowerCase()
    .replace(/[ ™\/,%]/g, '-') //add any more characters you want to replace here
    .replace(/-{2,}/g, '-')
    .replace(/ä/g, 'ae')
    .replace(/ö/g, 'oe')
    .replace(/ü/g, 'ue')
    .replace(/ß/g, 'ss');
}
